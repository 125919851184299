@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");

* {
    scroll-behavior: smooth;
}

:root {
    --font-family: 'Manrope', sans-serif;
  
    --gradient-text: linear-gradient(89.97deg, #AE67FA 1.84%, #F49867 102.67%);
    --gradient-bar: linear-gradient(103.22deg, #AE67FA -13.86%, #F49867 99.55%);
    
    --color-bg: #040C18;
    --color-footer : #031B34;
    --color-blog: #042c54;
    --color-text: #81AFDD;
    --color-subtext: #FF8A71;
  }

  .gradient__bg {
    background:-moz-radial-gradient(circle at 3% 25%, rgba(0, 40, 83, 1) 0%, rgba(4, 12, 24, 1) 25%);
  
    /* safari 5.1+,chrome 10+ */
    background:-webkit-radial-gradient(circle at 3% 25%, rgba(0, 40, 83, 1) 0%, rgba(4, 12, 24, 1) 25%);
  
    /* opera 11.10+ */
    background:-o-radial-gradient(circle at 3% 25%, rgba(0, 40, 83, 1) 0%, rgba(4, 12, 24, 1) 25%);
  
    /* ie 10+ */
    background:-ms-radial-gradient(circle at 3% 25%, rgba(0, 40, 83, 1) 0%, rgba(4, 12, 24, 1) 25%);
  
    /* global 92%+ browsers support */
    background:radial-gradient(circle at 3% 25%, rgba(0, 40, 83, 1) 0%, rgba(4, 12, 24, 1) 25%);
  }

  .gradient__text {
    font-family: Arial, Helvetica, sans-serif; 
    /* background: linear-gradient(to right, #cf23cf, #34ddde);  */
    /* background: linear-gradient(45deg, rgba(207,35,194,1) 12%, rgba(94,162,219,1) 56%, rgba(13,255,244,1) 100%); */
    background: linear-gradient(45deg, rgba(230,85,219,0.8743872549019608) 12%, rgba(94,162,219,0.8463760504201681) 66%, rgba(118,246,240,0.7091211484593838) 100%);
    -webkit-text-fill-color: transparent; 
    -webkit-background-clip: text; 
    background-clip: text;
  }

  .gradient__border {
    /* border: 1px solid;
    border-image: linear-gradient(45deg,#815a81, #558f8f) 10; */
/* 
    border: double 1px transparent;
    border-radius: 30px;
    background-image: linear-gradient(#040C18, #040C18), radial-gradient(circle at top left, #815a81,#558f8f);
    background-origin: border-box;
    background-clip: padding-box, border-box;

    transform: scale(1);
    transition: all 150ms ease-in-out;  */
    /* background-color: #1d1836; #040C18*/ 
    background-color: #07162c;
    box-shadow: 0 0 50px rgba(0,0,0,1);
  }

  .gradient__border:hover{
    transform: translateY(-5px);
    /* box-shadow: 0px 10px 20px 2px #AE67FA; */ /*#22f5f1*/
    box-shadow: 0 4px 10px rgba(12, 61, 137); /*#0C3D89*/
    /* transform: scale(1.1); */
    /* rotate(-5deg) */
  }

  /* .project-card:not(:hover){
    opacity: 0.8;
  } 

  .project-card:hover{
    opacity: 1;
  }  */

  .gradient__tag {
    /* background: linear-gradient(to right, #945394, #4c9e9e);  */
    /* background: linear-gradient(45deg, rgba(207,35,194,1) 12%, rgba(94,162,219,1) 56%, rgba(13,255,244,1) 100%); */
    background: linear-gradient(45deg, rgba(230,85,219,0.8743872549019608) 12%, rgba(94,162,219,0.8463760504201681) 66%, rgba(118,246,240,0.7091211484593838) 100%);
  }


